<template>
  <div class="card">
    <div v-if="!editMode" class="bynder-filter-summary">
      <div class="card-body" @click="enterEditMode">
        <div class="bynder-filter-buttons">
          <i class="bi bi-arrows-angle-expand" @click="enterEditMode"/>
        </div>
        <h5 class="card-title">{{ $t('form.bynder_filter.field.show', {field: field}) }}</h5>
        <h6 class="card-subtitle mb-2 text-muted">{{ $t('form.bynder_filter.operator.' + operator) }}</h6>
        <div v-if="Array.isArray(this.value)">
          <ul v-for="(valueItem, index) in this.value" :key="index">
            <li>{{ valueItem }}</li>
          </ul>
        </div>
        <p v-if="!Array.isArray(this.value)" class="card-text">{{ value }}</p>
        <div v-if="error" class="invalid-feedback d-inline-block">
          <span> {{ error }} </span>
        </div>
      </div>
    </div>
    <div v-if="editMode" class="card-body">
      <div class="bynder-filter-buttons">
        <i class="bi bi-trash2 me-2" @click="removeFilter"/>
        <i class="bi bi-arrows-angle-contract" @click="closeEditMode"/>
      </div>
      <h5 class="card-title">{{ $t('form.bynder_filter.field.show', {field: field}) }}</h5>
      <TextField
          class="w-75"
          id="field"
          :label="$t('form.bynder_filter.field.title')"
          v-model="field"
          @change="saveFilter()"
      />
      <SelectField
          class="w-75"
          :id="this.id + 'operator'"
          :label="$t('form.bynder_filter.field.title')"
          v-model="operator"
          @change="cleanFilterValue(operator)"
          :options="operators"
          :allowClear="false"
      />
      <TextField
          v-if="getOperatorType() === 'text'"
          class="mt-3 w-75"
          id="value"
          :label="$t('form.bynder_filter.value.title')"
          v-model="value"
      />
      <TagField
          v-if="getOperatorType() ===  'array'"
          :addOnKey="[13]"
          :placeholder="$t('form.bynder_filter.value.add_value')"
          class="mt-3 col-9"
          id="value"
          v-model="value"
      />
    </div>
  </div>
</template>

<script>

import TextField from "../../../../Form/TextField";
import SelectField from "../../../../Form/SelectField";
import TagField from "../../../../Form/TagField";

export default {
  name: "BynderFilterField",
  components: {
    TagField,
    TextField,
    SelectField
  },
  props: {
    id: String,
    filter: Object,
    error: String,
  },
  data () {
    return {
      editMode: false,
      field: this.filter.field ?? "",
      operator: this.filter.operator,
      value: this.filter.value ? this.formatValue(this.filter.value) : "",
      operators: [
        {id: 'EQUAL', type: "text", text: 'form.bynder_filter.operator.EQUAL'},
        {id: 'NOT_EQUALS', type: "text", text: 'form.bynder_filter.operator.NOT_EQUALS'},
        {id: 'IN', type: "array", text: 'form.bynder_filter.operator.IN'},
        {id: 'NOT_IN', type: "array", text: 'form.bynder_filter.operator.NOT_IN'},
        {id: 'NOT_EMPTY', type: "none", text: 'form.bynder_filter.operator.NOT_EMPTY'},
        {id: 'EMPTY', type: "none", text: 'form.bynder_filter.operator.EMPTY'},
        {id: 'CONTAINS', type: "text", text: 'form.bynder_filter.operator.CONTAINS'},
        {id: 'DOES_NOT_CONTAIN', type: "text", text: 'form.bynder_filter.operator.DOES_NOT_CONTAIN'},
        {id: 'REGEX', type: "text", text: 'form.bynder_filter.operator.REGEX'},
      ]
    }
  },
  watch: {
    value: {
      handler () {
        this.saveFilter();
      },
      deep: true
    }
  },
  methods: {
    formatValue (value) {
      if (Array.isArray(value)) {
        return value;
      }
      return String(value);
    },
    cleanFilterValue () {
      this.value = null;
      this.saveFilter();
    },
    getOperatorType () {
      const operatorObject = this.operators.find(operator => operator.id === this.operator);
      return operatorObject ? operatorObject.type : null;
    },
    enterEditMode () {
      this.editMode = true;
    },
    saveFilter () {
      this.$emit('bynder-filter-save', {
        field: this.field,
        operator: this.operator,
        value: this.value,
      });
    },
    closeEditMode () {
      this.editMode = false;
    },
    removeFilter () {
      this.$emit('bynder-filter-remove');
    }
  }
}
</script>

<style lang="scss">
.bynder-filter-buttons {
  top: 10px;
  right: 10px;
  position: absolute;
  color: lightgray;
  cursor: pointer;

  :hover {
    color: black;
  }
}

.bynder-filter-summary {
  cursor: pointer;

  :hover {
    background-color: lightgray;

    .bynder-filter-buttons {
      color: white;

      :hover {
        color: white;
      }
    }
  }
}

</style>

